/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages
			},
			finalize: function () {
				function initializeGoogleMap() {
					var latLng = {
						lat: 52.3568875,
						lng: 4.8537816
					};

					var map = new google.maps.Map(document.getElementById('map'), {
						zoom: 10,
						center: latLng,
						scrollwheel: false
					});

					var marker = new google.maps.Marker({
						position: latLng,
						map: map
					});
				}
				
				$('.nav-toggle').click(function (e) {
					e.preventDefault();
					$('.nav-toggle').toggleClass('nav-open');
					$('#wrapper').toggleClass('nav-open');
					$('nav.overlay').toggleClass('nav-open');
					$('html').toggleClass('nav-open');
					$('body').toggleClass('nav-open');
					$('.mm-hidden').toggleClass('nav-open');
				});

				$('.owl-carousel.news').owlCarousel({
					dots: true,
					margin: 20,
					nav: false,
					responsive: {
						0: {
							items: 1
						},
						758: {
							items: 2
						},
						982: {
							items: 3
						},
					}
				});

				$('.owl-carousel.testimonials').owlCarousel({
					dots: true,
					margin: 20,
					nav: false,
					responsive: {
						0: {
							items: 1
						},
						758: {
							items: 2
						}
					}
				});

				/*
				 ** Smoothscroll anchor
				 */
				$(function () {
					$('a.scroll-down').click(function () {
						if(location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
							var target = $(this.hash);
							if(target.length) {
								$('html, body').animate({
									scrollTop: target.offset().top - 100
								}, 1000);
								return false;
							}
						}
					});
				});

				/*
				 ** Create equal height for given elements
				 */
				var eqElements = [
					'.match-height',
				];

				for(var e_i = 0, e_l = eqElements.length; e_i < e_l; e_i++) {
					if($(eqElements[e_i]).length) {
						$(eqElements[e_i]).matchHeight();
					}
				}

				$("section aside div.wrapper").stick_in_parent({
					offset_top: 130
				});

				/*
				 ** Initialize Google Maps
				 */
				if($("#map").length) {
					google.maps.event.addDomListener(window, 'load', initializeGoogleMap);
				}
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if(fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
